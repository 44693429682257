import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Layout from "../components/layouts/main-layout/index.jsx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Columns, Container, Heading, Section } from "react-bulma-components";
import Event from "../components/bits/event/index.jsx";
import { pageLogo } from "./styles.module.scss";
import Body from "../modules/body/index.jsx";
import jsonldEvent from "../utils/helpers/jsonld-event.js";

class RootIndex extends React.Component {
  render() {
    const logo = get(this.props, "data.logoJazzDeXoc2022.childImageSharp");
    const logoNavigation = get(
      this.props,
      "data.logoNavigation.childImageSharp"
    );
    const page = get(this.props, "data.contentfulPage");
    const events = get(page, "events") || [];

    const logoImage = getImage(logo);

    const activeEvents = [...events]; //filteredEvents(events);

    return (
      <Layout
        background="brown"
        heroImage={page.heroImage}
        title={page.title}
        description={page.description}
        logoNavigation={logoNavigation}
        jsonLdData={activeEvents?.map((event) => jsonldEvent(event)) || []}
      >
        <Section>
          <Container>
            <Columns gap="8">
              <Columns.Column tablet={{ size: 3 }}>
                <div className={pageLogo}>
                  <GatsbyImage
                    image={logoImage}
                    alt="50è aniversari de les Nits amb Jazz a Cardedeu"
                    style={{
                      aspectRatio: `calc(${logoImage.width} / ${logoImage.height})`,
                      maxWidth: `min(${logoImage.width}px,40vw)`,
                      width: "100%",
                    }}
                    objectFit="contain"
                  />
                </div>
              </Columns.Column>
              <Columns.Column tablet={{ size: 9 }}>
                <Body body={page.body} />
                {activeEvents?.length > 0 && (
                  <>
                    {page.eventsTitle && (
                      <Heading renderAs="h2">{page.eventsTitle}</Heading>
                    )}
                    {activeEvents?.map((eventData) => (
                      <Event {...eventData} key={eventData.id} transparent />
                    ))}
                  </>
                )}
              </Columns.Column>
            </Columns>
          </Container>
        </Section>
      </Layout>
    );
  }
}

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    contentfulPage(slug: { eq: "home" }) {
      ...PageData
    }
    logoNavigation: file(extension: {}, name: { eq: "logo-navigation" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    logo: file(extension: {}, name: { eq: "logo-nits-amb-jazz" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    logoJazzDeXoc2022: file(
      extension: {}
      name: { eq: "logo-jazz-de-xoc-2022" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    allContentfulEvent(sort: { order: ASC, fields: dateTime }) {
      nodes {
        ...EventData
      }
    }
    site {
      siteMetadata {
        description
        title
      }
    }
  }
`;
